import React from "react";
import { Link } from "gatsby";

export default function Author({
  date,
  readingTime,
  nickname,
  avatar,
  twitter,
}) {
  return (
    <div className="d-flex align-items-center">
      <img className="rounded-circle" src={avatar} alt={nickname} width="70" />
      <small className="ml-3">
        {" "}
        {nickname}
        <span>
          <Link
            to={twitter}
            className="btn btn-outline-success btn-sm btn-round ml-1"
          >
            Follow
          </Link>
        </span>
        <span className="text-muted d-block mt-1">
          {date} · {readingTime}
        </span>
      </small>
    </div>
  );
}
