import React from "react";
import { Link } from "gatsby";

export default function LargeCategoryList({ categories }) {
  return (
    <p className="text-uppercase font-weight-bold">
      <span className="catlist">
        {categories.map((category, index) => (
          <span key={category}>
            {index !== 0 && " "}
            <Link
              className="sscroll text-danger"
              to={`/categories/${category}`}
            >
              {category}
            </Link>
          </span>
        ))}
      </span>
    </p>
  );
}
