import React from "react";
import { graphql } from "gatsby";
import {
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";
import Layout from "../components/Layout";
import LargeCategoryList from "../components/LargeCategoryList";
import Author from "../components/Author";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function BlogTemplate({ data }) {
  const url = `${data.site.siteMetadata.siteURL}${data.markdownRemark.fields.slug}`;
  return (
    <Layout>
      <div className="jumbotron jumbotron-fluid mb-3 pl-0 pt-0 pb-0 bg-white position-relative">
        <div className="h-100 tofront">
          <div className="row justify-content-between">
            <div className="col-md-6 pr-0 pr-md-4 pt-4 pb-4 align-self-center">
              <LargeCategoryList
                categories={data.markdownRemark.frontmatter.categories}
              />
              <h1 className="display-4 mb-4 article-headline">
                {data.markdownRemark.frontmatter.title}
              </h1>
              <Author
                date={data.markdownRemark.frontmatter.date}
                readingTime={data.markdownRemark.fields.readingTime.text}
                nickname={data.site.siteMetadata.nickname}
                avatar={data.site.siteMetadata.avatar}
                twitter={data.site.siteMetadata.twitter}
              />
            </div>
            <div className="col-md-6 pr-0 align-self-center">
              {data.markdownRemark.frontmatter.image.childImageSharp ? (
                <GatsbyImage
                  id={data.markdownRemark.fields.slug}
                  className="rounded"
                  image={getImage(data.markdownRemark.frontmatter.image)}
                  alt={data.markdownRemark.frontmatter.title}
                />
              ) : (
                <img
                  src={data.markdownRemark.frontmatter.image.publicURL}
                  alt={data.markdownRemark.frontmatter.title}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="container-lg pt-4 pb-4">
        <div className="row justify-content-center">
          <div className="col-lg-2 pr-4 mb-4 col-md-12">
            <div className="sticky-top sticky-top-offset text-center">
              <div className="text-muted">Share this</div>
              <div className="share d-inline-block">
                <TwitterShareButton url={url}>
                  <TwitterIcon size={40} round />
                </TwitterShareButton>
                <HatenaShareButton url={url}>
                  <HatenaIcon size={40} round />
                </HatenaShareButton>
                <FacebookShareButton url={url}>
                  <FacebookIcon size={40} round />
                </FacebookShareButton>
                <LinkedinShareButton url={url}>
                  <LinkedinIcon size={40} round />
                </LinkedinShareButton>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-8">
            <article
              className="article-post"
              dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteURL
        nickname
        avatar
        twitter
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        categories
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
          publicURL
        }
        date(formatString: "MMM D, YYYY")
      }
      fields {
        slug
        readingTime {
          text
        }
      }
    }
  }
`;
